import { createRouter, createWebHistory } from 'vue-router';
import UserDashboard from '../views/UserDashboard.vue';
import Login from '../components/auth/LoginPage.vue';
import DashboardUser from '../components/DashboardUser.vue';
import UserForm from '../components/users/UserForm.vue';
import GastoManager from "@/components/gastos/GastoManager.vue";
import ReceitasManager from "@/components/receitas/ReceitasManager.vue";
import AplicacaoManager from "@/components/aplicacao/AplicacaoManager.vue";
import DinheiroCofre from "@/components/cofre/DinheiroCofre.vue";
import SaldoBanco from "@/components/saldos/SaldoBanco.vue";
import SaldoBolsa from "@/components/saldos/SaldoBolsa.vue";
import RelatorioControl from "@/components/relatorio/RelatorioControl.vue";
import EditUser from "@/components/users/EditUser.vue";
import CalendarioPage from "@/components/calendario/CalendarioPage.vue";
import TarefasManeger from "@/components/tarefas/TarefasManeger.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
  {
        path: '/user',
        component: UserDashboard,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'dashboard',
                name: 'DashboardUser',
                component: DashboardUser,
            },
            {
                path: 'usuarios',
                name: 'AdminUserForm',
                component: UserForm,
            },
            {
              path:'gastos',
                name:'Gastos',
                component:GastoManager
            },
            {
              path:'receitas',
              name:'Receitas',
              component: ReceitasManager
            },
            {
              path:'aplicacoes',
              name:'Aplicacoes',
              component: AplicacaoManager
            },
            {
              path:'dinheiro-cofre',
              name:'DinheiroCofre',
              component: DinheiroCofre
            },
            {
              path:'saldo-banco',
              name:'SaldoBanco',
              component: SaldoBanco
            },
            {
              path:'saldo-bolsa',
              name:'SaldoBolsa',
              component: SaldoBolsa
            },
            {
              path:'relatorios',
              name:'Relatorios',
              component: RelatorioControl
            },
            {
              path: 'usuarios/:id',
              name: 'AdminUserFormEdit',
              component: EditUser
            },
            {
                path: 'calendario',
                name: 'Calendario',
                component: CalendarioPage
            },
            {
                path: 'tarefas',
                name: 'Tarefas',
                component: TarefasManeger
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const currentUser = localStorage.getItem('token');

    if (requiresAuth && !currentUser) {
        next({ path: '/' });
    } else {
        next();
    }
});

export default router;
