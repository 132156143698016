<template>
  <div class="max-w-lg mx-auto p-8 bg-white rounded-lg shadow-lg">
    <h2 class="text-2xl font-bold mb-6 text-center">Editar Usuário</h2>
    <form @submit.prevent="updateUser">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">Nome de Usuário</label>
        <input v-model="usuario.username" id="username" type="text"
          class="border rounded w-full py-2 px-3 text-gray-700" required />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">Senha</label>
        <input v-model="usuario.password" id="password" type="password"
          class="border rounded w-full py-2 px-3 text-gray-700" required />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="role">Telefone</label>
        <input v-model="usuario.telefone" id="phone" type="text" class="border rounded w-full py-2 px-3 text-gray-700"
          required />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="role">Função</label>
        <input v-model="usuario.role" id="role" type="text" class="border rounded w-full py-2 px-3 text-gray-700"
          required />
      </div>

      <div class="flex justify-end">
        <button type="submit" class="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Salvar
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const apiUrl = 'https://web-production-54e4.up.railway.app';

const router = useRouter();
const userId = localStorage.getItem('usuario_id');

const usuario = ref({
  username: '',
  password: '',
  role: '',
  telefone: '',
});

const fetchUser = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autenticação ausente');
    }
    const response = await axios.get(`${apiUrl}/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    usuario.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar usuário:', error);
    alert('Erro ao buscar o usuário.');
  }
};

const updateUser = async () => {
  try {
    const token = localStorage.getItem('token');
    await axios.put(`${apiUrl}/api/users/${userId}`, usuario.value, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    alert('Usuário atualizado com sucesso!');

    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');

    router.push('/');
  } catch (error) {
    console.error('Erro ao atualizar usuário:', error);
    alert('Erro ao atualizar o usuário.');
  }
};

onMounted(fetchUser);
</script>

<style scoped>
input {
  transition: border-color 0.2s ease;
}

input:focus {
  border-color: #3182ce;
  outline: none;
}

button {
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #2b6cb0;
}
</style>
